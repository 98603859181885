import React from 'react';

// Assets
import crizcrozlogo from '../../assets/CrizcrozWebTempLogo.png';
// import WebDots from '../../assets/WebDotsFit.png';

// Style
import '../../style/App.css';
import '../../style/additionalStyle.css';
import { Row, Col } from 'react-bootstrap';

// Components
// import BottomComponent from './component/BottomComponent';
import BottomComponent2 from './component/BottomComponent2';
import ConverterComponent from './component/ConverterComponent';
import Copyright from './component/Copyright';

class Main extends React.Component {
  render() {
  return (
    <div className="App">
    <div className="container-fluid imageDots">
      {/* <img src={WebDots} className="img-fluid imageDots" alt="imageDots" /> */}
      <Row className="vh-100 py-4">
        <Col className="h-100 d-flex flex-column" lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }}>
          <Row className="px-3">
            <img src={crizcrozlogo} className="img-fluid logoCrizcroz" alt="crizcrozlogo" />
          </Row>
          <Row className="flex-fill">
            <Col className="text-left" lg="12" xl={{ span: 6 }}>
              <Row className="flex-fill flex-column">
                <Col className="margin-atas headerTeks1">
                The most convenient<br></br>money changing experience<br></br>is coming to you soon.
                </Col>
                <Col className="mt-3 headerTeks2">
                  The app is on its way, be the first to try!
                </Col>
              </Row>
            </Col>
            <Col lg="12" xl={{ span: 5, offset: 1 }}>
              <div className="margin-atas pb-3 row justify-content-center align-items-center px-3"> {/* add h-100 untuk posisi di tengah */}
                <ConverterComponent />
              </div>
              <BottomComponent2 />
            </Col>
          </Row>
          <Row className="px-3">
            {/* <BottomComponent />
            <BottomComponent2 /> */}
            <Copyright />
          </Row>
        </Col>
      </Row>
    </div>
    </div>
  );
}
}

export default Main;

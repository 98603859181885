import { combineReducers } from 'redux';

import {
    userlocation,
    listcurrencies,
    submitapplicant
  } from './globalReducer';

const rootReducer = combineReducers({
    userLocation: userlocation,
    listCurrencies: listcurrencies,
    submitapplicant: submitapplicant
});

export default rootReducer;
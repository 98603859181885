import React from 'react';

//style
import '../../../style/additionalStyle.css';

// Bootstrap Component
// import { 
//     Form,
//     Row,
//     Button
//  } from 'react-bootstrap';

export class Copyright extends React.Component {

  render() {
        return (
            <div className="bottomComponentStyle">
                &copy; Copyright 2019 PT. Criz Croz Indonesia
            </div>
        );
    }
}

export default (Copyright);

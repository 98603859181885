import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

//style
import '../../../style/additionalStyle.css';

// Bootstrap Component
import { 
    Modal,
    Form,
    Button
 } from 'react-bootstrap';

 // Action
 import { submitApplicant } from '../../../store/actions/rootAction'; 

export class BottomComponent2 extends React.Component {
    state = {
        Show: false,
        form: false,
        email: '',
        errorMessage: '',
        splitEmail: ''
      }
    
      handleShow = () => {
          this.setState({ Show: true });
      }
    
      handleClose = () => {
        this.setState({ 
          Show: false,
          email: '',
          errorMessage: '' 
        });
      }
    
      handleClick = (e) => {
        e.preventDefault();
        this.setState({ form: true })
      }
    
      handleChange = (e) => {
        this.setState({ 
          email: e.target.value,
          errorMessage: ''
        })
      }
    
      handleSubmit = async(e) => {
        e.preventDefault();
        const emailAddress = this.state.email;
        var splitemail = emailAddress.split("@");
        // console.log(splitemail);
        if (!emailAddress) {
            this.setState({ errorMessage: "Please input your email address!" })
        } 
        else {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const emailFormat = (re.test(String(emailAddress).toLowerCase()));
            if (emailFormat === true) {
                const country_id = this.props.userLocation.countryCode;
                const email = this.state.email;
                const result = await this.props.submitApplicant(country_id,email);
                if (result.message === 'success'){
                  this.setState({ 
                    splitEmail: splitemail[0],
                    form: 'submited' 
                })
                } else {
                  this.setState({ errorMessage: "There's something error with our system, please try again!." })
                }
            } else {
                this.setState({ errorMessage: "That's not a valid e-mail address." })
            }
        }
      }

      handleForm = (e) => {
        e.preventDefault();
      }
    
        render() {
        // console.log('get country id : ', this.props.userLocation.countryCode)
        const { Show, form, email, splitEmail } = this.state;
            return (
                <div className="bottomComponentStyle2">
                    <Button onClick={this.handleShow} block>I'm interested!</Button>
                    <Modal show={Show} onHide={this.handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body className="p-4">
                            { form === false ? 
                                <div>
                                    <h5 className="modalTitle">
                                        Let us know your e-mail.<br></br>
                                        We'll tell you when we're ready!
                                    </h5>
                                    <Form autoComplete="off" onSubmit={this.handleForm}>
                                    <Form.Group controlId="modalFormEmail">
                                      <Form.Label className="modalTitle2">E-mail</Form.Label>
                                      {this.state.errorMessage ?
                                        <Form.Control className="modalFormEmail-error" name="email" type="text" value={email} onChange={this.handleChange} />
                                      :
                                        <Form.Control className="modalFormEmail" name="email" type="text" value={email} onChange={this.handleChange} />
                                      }
                                      {this.state.errorMessage ?
                                        <Form.Text className="textAlert-Modal">{this.state.errorMessage}</Form.Text>
                                      :
                                        <Form.Text className="textAlert-Modal-blank">Allert Message!</Form.Text>
                                      }
                                    </Form.Group>
                                    <Button className="modalSubmit1" onClick={this.handleSubmit} block>Notify me!</Button>
                                    <Button className="modalSubmit2" onClick={this.handleClose} block>Cancel</Button>
                                    <h5 className="modalTitle3 mt-4">
                                        Promise you, we will keep your privacy and won't send spam mails!
                                    </h5>
                                    </Form>
                                </div>
                            :
                                <div>
                                    <h5 className="modalTitle2">
                                        Thanks {splitEmail}!<br></br>
                                        We'll notify you as soon as we're ready!
                                    </h5>
                                    <Button className="modalSubmit1 mt-4" onClick={this.handleClose} block>Close</Button>
                                </div>
                            }
                            
                        </Modal.Body>
                    </Modal>
                </div>
            );    
        }
}

const mapStateToProps = (state) => {
    return {
      submitapplicant : state.submitapplicant,
      userLocation : state.userLocation
    }
  }

  export default compose(
    connect(mapStateToProps,{submitApplicant})
  )(BottomComponent2);
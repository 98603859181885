import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// Libraries
import NumberFormat from 'react-number-format';

//style
import '../../../style/additionalStyle.css';

// Assets
import swap from '../../../assets/swap.png';

// Actions
import { getUserLocation, getListCurrencies } from '../../../store/actions/rootAction';

// Bootstrap
import { Form } from 'react-bootstrap';

class ConverterComponent extends React.Component {
    state = {
        userLocationData: [],
        dataCurrencies: [],
        rates: [],
        baseCurrencies: '',
        targetCurrencies: '',
        baseAmount: '',
        targetAmount: '',
        rate: 0,
        info: '',
        base_focus: false,
        target_focus: false
    }

  componentDidMount = async() => {
    const userLocationData = this.props.userLocation;
    if (userLocationData.length === 0){
        const result = await this.props.getUserLocation();
        // console.log(result);
        if (result.message === 'success'){
          const locationId = result.locationId;
          await this.props.getListCurrencies(locationId);
        } else {
          const locationId = "ID";
          await this.props.getListCurrencies(locationId);
        }
    }
  }

  UNSAFE_componentWillReceiveProps = async(getProps) => {
      // console.log(getProps)
      await this.setState({ 
        userLocationData: getProps.userLocation,
        dataCurrencies: getProps.listCurrencies.currencies
      })
    // console.log(this.state)
    if (this.state.userLocationData && this.state.dataCurrencies) {
      const BaseCurrency = "IDR";
      const data = this.state.dataCurrencies;
      var dataRates = await data.filter(function(rates) {
        return rates.id === BaseCurrency;
      });
      // console.log(dataRates);
      await this.setState({ 
        baseCurrencies: BaseCurrency,
        rates: dataRates[0].detail.rates
      })
      const TargetCurrency = "AUD";
      const data2 = dataRates[0].detail.rates;
      var dataRate =  await data2.filter(function(rate) {
        return rate.target_currency === TargetCurrency;
      });
      //console.log(dataRate);
      //console.log(dataRate[0].exchange_rate);
      const rate = Number(dataRate[0].exchange_rate);
      const fixedRate = rate.toFixed(6);
      await this.setState({ 
          targetCurrencies: TargetCurrency,
          rate: rate,
          info: '1 ' + this.state.baseCurrencies + ' = ' + fixedRate + ' ' + TargetCurrency
      })
    }
  }

  changeBaseCurrency = async(e) => {
    const value = e.target.value;
    const data = this.state.dataCurrencies;
    var dataRates =  await data.filter(function(rates) {
        return rates.id === value;
    });
    //console.log(dataRates);
    // console.log(dataRates[0].detail.rates);
    await this.setState({ 
        baseCurrencies: value,
        rates: dataRates[0].detail.rates,
     })
    const TargetCurrency = this.state.rates[0].target_currency;
    const data2 = this.state.rates;
    var dataRate =  await data2.filter(function(rate) {
      return rate.target_currency === TargetCurrency;
    });
    const rate = Number(dataRate[0].exchange_rate);
    const fixedRate = rate.toFixed(6);
    await this.setState({ 
      targetCurrencies: TargetCurrency,
      rate: rate,
      info: '1 ' + this.state.baseCurrencies + ' = ' + fixedRate + ' ' + TargetCurrency
   })
   if (this.state.baseAmount) {
    var result = Number(this.state.baseAmount * rate);
    var fixedResult = result.toFixed(2);
    this.setState({ 
        targetAmount: fixedResult 
    })
  }
  }

  changeTargetCurrency = async(e) => {
    const value = e.target.value;
    const data = this.state.rates;
    var dataRate =  await data.filter(function(rate) {
        return rate.target_currency === value;
    });
    //console.log(dataRate);
    //console.log(dataRate[0].exchange_rate);
    const rate = Number(dataRate[0].exchange_rate);
    const fixedRate = rate.toFixed(6);
    await this.setState({ 
        targetCurrencies: value,
        rate: rate,
        info: '1 ' + this.state.baseCurrencies + ' = ' + fixedRate + ' ' + value
     })
    if (this.state.baseAmount) {
      var result = Number(this.state.baseAmount * rate);
      var fixedResult = result.toFixed(2);
      this.setState({ 
          targetAmount: fixedResult 
      })
    }
  }

  changeBaseAmount = values => {
    //console.log(values);
    if (this.state.base_focus === true) {
      var result = Number(values.value * this.state.rate);
      var fixedResult = result.toFixed(2);
      this.setState({ 
          baseAmount: values.value,
          targetAmount: fixedResult 
      })
    }
  }

  baseFocus = () => {
    this.setState({ base_focus: true })
  }
  baseBlur = () => {
    this.setState({ base_focus: false })
  }

  changeTargetAmount = values => {
    if (this.state.target_focus === true) {
      var result = Number(values.value / this.state.rate);
      var fixedResult = result.toFixed(2);
      this.setState({ 
          targetAmount: values.value,
          baseAmount: fixedResult
        })
    }
  }
  targetFocus = () => {
    this.setState({ target_focus: true })
  }
  targetBlur = () => {
    this.setState({ target_focus: false })
  }

  handleSwap = async (e) => {
    e.preventDefault();
    const baseBefore = this.state.baseCurrencies;
    const targetBefore = this.state.targetCurrencies;
    // const targetAmountBefore = this.state.targetAmount;
    await this.setState({ 
        baseCurrencies: targetBefore,
        targetCurrencies: baseBefore,
        // baseAmount: targetAmountBefore,
        rate: 0,
        info: '',
        baseAmount: '',
        targetAmount: ''
     })
    const data = this.state.dataCurrencies;
    const baseCur = this.state.baseCurrencies;
    var dataRates =  await data.filter(function(rates) {
        return rates.id === baseCur;
    });
    //console.log(dataRates);
    //console.log(dataRates[0].detail.rates);
    await this.setState({ 
        rates: dataRates[0].detail.rates,
     })
    const targetCur = this.state.targetCurrencies;
    //console.log(targetCur);
    const rates = this.state.rates;
    //console.log(rates);
    var dataRate =  await rates.filter(function(rate) {
        return rate.target_currency === targetCur;
    });
    // console.log("datarate", dataRate);
    // console.log(dataRate[0].exchange_rate);
    const rate = Number(dataRate[0].exchange_rate);
    const fixedRate = rate.toFixed(6);
    await this.setState({ 
        rate: rate,
        info: '1 ' + this.state.baseCurrencies + ' = ' + fixedRate + ' ' + baseBefore
     })
    // const result = Number(this.state.baseAmount * rate);
    // const fixedResult = result.toFixed(2);
    // await this.setState({ 
    //     targetAmount: fixedResult
    //  })
  }

  render() {
      const {userLocationData, dataCurrencies, baseCurrencies, rates, targetCurrencies, info, baseAmount, targetAmount } = this.state;
      // console.log(this.state);
      if (!dataCurrencies || !userLocationData || !baseCurrencies || !targetCurrencies) {
        return(
            <div className="divCurrencyConverter px-5 py-4">
              <div className="row">
                <h5 className="Teks1-blank">Change</h5>
              </div>
              <div className="row d-flex flex-nowrap">
                <Form.Group controlId="Form.ControlSelect1">
                  <Form.Control as="select" value={baseCurrencies} onChange={this.changeBaseCurrency} className="select-1" disabled>
                    <option value="">Load</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="baseAmount" className="flex-grow-1">
                  <Form.Control value={baseAmount} className="inputform-1" disabled name="baseAmount" type="number" pattern="[0-9]*" placeholder="input an amount" onChange={this.changeBaseAmount} />
                </Form.Group>
              </div>
              <div className="d-flex justify-content-center mt-3 loader">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
              </div>
              <div className="row">
                <h5 className="Teks1-blank">Get</h5>
              </div>
              <div className="row d-flex flex-nowrap">
                <Form.Group controlId="Form.ControlSelect2">
                    <Form.Control as="select" value={targetCurrencies} onChange={this.changeTargetCurrency} className="select-2" disabled>
                        <option value="">Load</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="targetamount" className="flex-grow-1">
                  <Form.Control name="targetamount" value={targetAmount} onChange={this.changeTargetAmount} className="inputform-2" disabled type="number" pattern="[0-9]*" placeholder="input an amount"/>
                </Form.Group>
              </div>
              <div className="row">
                <h5 className="infoTeksBlank">Rates Info</h5>
              </div>
            </div>
          );
      } else {
        return(
          <div className="divCurrencyConverter px-5 py-4">
              <div className="row">
                <h5 className="Teks1">Change</h5>
              </div>
              <div className="row d-flex flex-nowrap">
                <Form.Group controlId="Form.ControlSelect1">
                  <Form.Control value={baseCurrencies} onChange={this.changeBaseCurrency} as="select" className="select-1">
                    {dataCurrencies.map((item, index) => (
                        <option key={index} value={item.id}>{item.id}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="baseAmount" className="flex-grow-1">
                  <NumberFormat 
                    allowNegative={false} 
                    className="inputform-1"
                    customInput={Form.Control}
                    displayType="input"
                    name="baseAmount"
                    onValueChange={this.changeBaseAmount}
                    thousandSeparator
                    value={baseAmount}
                    pattern="[0-9]*" 
                    placeholder="input an amount"
                    maxLength="20"
                    onFocus={this.baseFocus}
                    onBlur={this.baseBlur}
                  />
                  {/* <Form.Control value={baseAmount} className="inputform-1" name="baseAmount" type="number" pattern="[0-9]*" placeholder="input an amount" onChange={this.changeBaseAmount} /> */}
                </Form.Group>
              </div>
              <div className="row justify-content-end">
                <a  href="#foo" onClick={this.handleSwap} className="swapLink">
                  <img src={swap} className="SwapIcon" alt="swapicon" />
                  <h5 className="Teks2">  Swap</h5>
                </a>
              </div>
              <div className="row">
                <h5 className="Teks1">Get</h5>
              </div>
              <div className="row d-flex flex-nowrap">
                {rates.length === 0 ? (
                    <Form.Group controlId="Form.ControlSelect2">
                        <Form.Control as="select" value={targetCurrencies} onChange={this.changeTargetCurrency} className="select-2">
                            <option value="">...</option>
                        </Form.Control>
                     </Form.Group>
                ) : (
                    <Form.Group controlId="Form.ControlSelect2">
                        <Form.Control as="select" value={targetCurrencies} onChange={this.changeTargetCurrency} className="select-2">
                            {rates.map((item, index) => (
                                <option key={index} value={item.target_currency}>{item.target_currency}</option>
                            ))}
                        </Form.Control>
                     </Form.Group>
                )}
                <Form.Group controlId="targetamount" className="flex-grow-1">
                  <NumberFormat 
                    allowNegative={false} 
                    className="inputform-2"
                    customInput={Form.Control}
                    displayType="input"
                    name="targetamount"
                    onValueChange={this.changeTargetAmount}
                    thousandSeparator
                    value={targetAmount}
                    pattern="[0-9]*" 
                    placeholder="input an amount"
                    maxLength="20"
                    onFocus={this.targetFocus}
                    onBlur={this.targetBlur}
                  />
                  {/* <Form.Control name="targetamount" value={targetAmount} onChange={this.changeTargetAmount} className="inputform-2" type="number" pattern="[0-9]*" placeholder="input an amount"/> */}
                </Form.Group>
              </div>
              <div className="row">
                {info ? <h5 className="infoTeks">{info}</h5> : <h5 className="infoTeksBlank">Rates Info</h5>}
              </div>
          </div>
        );
      }
        
    }
}

const mapStateToProps = (state) => {
    return {
      userLocation : state.userLocation,
      listCurrencies: state.listCurrencies
    }
  }

export default compose(
    connect(mapStateToProps,{getUserLocation,getListCurrencies})
  )(ConverterComponent);
import React from 'react';

// Style
import '../../style/App.css';
import '../../style/additionalStyle.css';

class PrivacyPolicy extends React.Component {
  render() {
  return (
    <div>
        <div className="jumbotron">
        <h2>Privacy Policy</h2> <p className="text-muted">
        CrizCroz Corp. built the CrizCroz app as
        a Commercial app. This SERVICE is provided by
        CrizCroz Corp.  and is intended for
        use as is.
        </p> <p className="text-muted">
        This page is used to inform visitors regarding
        our policies with the collection, use, and
        disclosure of Personal Information if anyone decided to use
        our Service.
        </p> <p className="text-muted">
        If you choose to use our Service, then you agree
        to the collection and use of information in relation to this
        policy. The Personal Information that we collect is
        used for providing and improving the Service.
        We will not use or share your
        information with anyone except as described in this Privacy
        Policy.
        </p> <p className="text-muted">
        The terms used in this Privacy Policy have the same meanings
        as in our Terms and Conditions, which is accessible at
        CrizCroz unless otherwise defined in this Privacy
        Policy.
        </p> </div> <div className="jumbotron"><p><strong>Information Collection and Use</strong></p> 
        <p className="text-muted">
        For a better experience, while using our Service,
        we may require you to provide us with certain
        personally identifiable information, including but not limited to GPS Locations, Full Name, Nationality, Email Address, Phone Number, and Legal ID. The
        information that we request will be
        retained by us and used as described in this privacy policy.
        </p> <p className="text-muted">
        The app does use third party services that may collect
        information used to identify you.
        </p> <div><p className="text-muted">
            Link to privacy policy of third party service providers
            used by the app
        </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
                <li><a href="https://www.apple.com/legal/privacy/" target="_blank" rel="noopener noreferrer">App Store Services</a></li>
            </ul></div> 
        </div>
        <div className="jumbotron"><p><strong>Log Data</strong></p> 
            <p className="text-muted">
        We want to inform you that whenever
        you use our Service, in a case of an error in the
        app we collect data and information (through third
        party products) on your phone called Log Data. This Log Data
        may include information such as your device Internet
        Protocol (“IP”) address, device name, operating system
        version, the configuration of the app when utilizing
        our Service, the time and date of your use of the
        Service, and other statistics.
        </p></div> 
        
        <div className="jumbotron"><p><strong>Cookies</strong></p> 
        <p className="text-muted">
        Cookies are files with a small amount of data that are
        commonly used as anonymous unique identifiers. These are
        sent to your browser from the websites that you visit and
        are stored on your device's internal memory.
        </p> <p className="text-muted">
        This Service does not use these “cookies” explicitly.
        However, the app may use third party code and libraries that
        use “cookies” to collect information and improve their
        services. You have the option to either accept or refuse
        these cookies and know when a cookie is being sent to your
        device. If you choose to refuse our cookies, you may not be
        able to use some portions of this Service.
        </p></div> 
        <div className="jumbotron"><p><strong>Service Providers</strong></p> 
        <p className="text-muted">
        We may employ third-party companies
        and individuals due to the following reasons:
        </p> <ul className="text-muted"><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> 
        <p className="text-muted">
        We want to inform users of this
        Service that these third parties have access to your
        Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated
        not to disclose or use the information for any other
        purpose.
        </p></div> 
        <div className="jumbotron"><p><strong>Security</strong></p> 
        <p className="text-muted">
        We value your trust in providing us
        your Personal Information, thus we are striving to use
        commercially acceptable means of protecting it. But remember
        that no method of transmission over the internet, or method
        of electronic storage is 100% secure and reliable, and
        we cannot guarantee its absolute security.
        </p></div> 
        <div className="jumbotron"><p><strong>Links to Other Sites</strong></p> 
        <p className="text-muted">
        This Service may contain links to other sites. If you click
        on a third-party link, you will be directed to that site.
        Note that these external sites are not operated by
        us. Therefore, we strongly advise you to
        review the Privacy Policy of these websites.
        We have no control over and assume no
        responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
        </p></div> 
        <div className="jumbotron"><p><strong>Children’s Privacy</strong></p> 
        <p className="text-muted">
        These Services do not address anyone under the age of 13.
        We do not knowingly collect personally
        identifiable information from children under 13. In the case
        we discover that a child under 13 has provided
        us with personal information,
        we immediately delete this from our servers. If you
        are a parent or guardian and you are aware that your child
        has provided us with personal information, please contact
        us so that we will be able to do
        necessary actions.
        </p></div> 
        <div className="jumbotron"><p><strong>Changes to This Privacy Policy</strong></p> 
        <p className="text-muted">
        We may update our Privacy Policy from
        time to time. Thus, you are advised to review this page
        periodically for any changes. We will
        notify you of any changes by posting the new Privacy Policy
        on this page. These changes are effective immediately after
        they are posted on this page.
        </p></div> 
        <div className="jumbotron"><p><strong>Contact Us</strong></p> 
        <p className="text-muted">
        If you have any questions or suggestions about
        our Privacy Policy, do not hesitate to contact
        us at crizcroz.id@gmail.com.
        </p></div>
    </div>
  );
}
}

export default PrivacyPolicy;
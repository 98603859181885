import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Component
import Main from './view/main';
import PrivacyPolicy from './view/PrivacyPolicy'

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        {/* <Redirect exact from="/" to="/" /> */}
        <Route component={Main} exact path="/" />
        <Route component={PrivacyPolicy} exact path="/privacy-policy" />
        <Redirect to="/" />
      </Switch>
    );
  }
}

// USER LOCATION DATA
export const userlocation = (state = [], action) => {
    // console.log(action.type);
    switch (action.type) {
      case 'GET_USER_LOCATION':
        return action.payload;
      default:
        return state;
    }
  };

// CURRENCIE LIST DATA
export const listcurrencies = (state = [], action) => {
    // console.log(action.type);
    switch (action.type) {
      case 'GET_LIST_CURRENCIES':
        return action.payload;
      default:
        return state;
    }
  };

// SUBMIT APPLICANT
export const submitapplicant = (state = [], action) => {
  // console.log(action.type);
  switch (action.type) {
    case 'SUBMIT_APPLICANT':
      // console.log('Submit applicant :' + action.payload)
      return action.payload;
    default:
      return state;
  }
};
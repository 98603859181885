// import axios from 'axios';
import { crizcrozGetLink } from '../../api/';
import publicIp from 'public-ip';
import iplocation from "iplocation";

// IP / Lokasi jika error maka sementara di default IDR

export const getUserLocation = () => async dispatch => {
    var ipPublic = await publicIp.v4();
    if (!ipPublic) {
      console.log("IP public tidak ditemukan")
      ipPublic = "120.188.93.145";
    }
    const result = await iplocation(ipPublic)
    .then(response => {
        // console.log(response);
        dispatch({ type: 'GET_USER_LOCATION', payload: response });
        return {
          status_code: 200,
          message: 'success',
          locationId: response.countryCode
        };
      })
      .catch(error => {
        // Error 😨
        if (error.response) {
          // console.log(error.response);
          return {
            status_code: 400,
            message: error.response,
            locationId: 'ID'
          };
        }
      });
    return result;
}

export const getListCurrencies = (locationId) => async dispatch => {
    const result = await crizcrozGetLink.get(`/currencies/all/${locationId}`)
    .then(response => {
        // console.log(response.status);
        // console.log(response.data);
        dispatch({ type: 'GET_LIST_CURRENCIES', payload: response.data });
        return {
          status_code: response.status,
          message: response.data.message,
        };
      })
      .catch(error => {
        // Error 😨
        if (error.response) {
          // console.log(error.response.status);
          // console.log(error.response.data);
          return {
            status_code: error.response.status,
            message: error.response.data
          };
        }
      });
    return result;
}

export const submitApplicant = (country_id, email) => async dispatch => {
  // console.log('send country_id : ' + country_id);
  // console.log('send email : ' + email)
  const result = await crizcrozGetLink.post('/betaapplicants/applyemail/',
  {
    country_id: country_id,
    email: email
  })
  .then(response => {
      // console.log(response.status);
      // console.log(response.data);
      dispatch({ type: 'SUBMIT_APPLICANT', payload: response.data.message });
      return {
        status_code: response.status,
        message: response.data.message,
      };
    })
    .catch(error => {
      // Error 😨
      if (error.response) {
        // console.log(error.response.status);
        // console.log(error.response.data);
        return {
          status_code: error.response.status,
          message: error.response.data
        };
      }
    });
  return result;
}